<template>
    <div class="page-grids">
        <!-- 网格码提示 -->
        <div class="grids-tips" v-if="gridsFlag">
            网格码：
            <span v-show="gridsInfoShowFlag">{{ gridsInfo }}</span>
            <div v-show="!gridsInfoShowFlag" class="grids-custom-loader"></div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import _ from 'lodash'
import cesiumSpaceAPI from '@/utils/cesiumSpace'
import * as turf from '@turf/turf'
import EventBus from "@/assets/ligature";
import gridListDataWUHU_Origin from '@/data/grid/airSpace_wuhu_origin.json';
import gridListDataSHENZHEN_Origin from '@/data/grid/airSpace_shenzhen_origin.json';
let threeDimensionalGridprimitivesList = [];
let gridBoxList = [];
let airLineEntityColloction = null;
let labelDivList = [];
let myheight = 5000;
let viewerExtent = [];
let currentGridsOriginData = gridListDataWUHU_Origin;
let airlineGridsPrimitiveList = [];
let airlineGridsPrimitiveOutLintList = [];
let currentLevel = 0;
export default {
    components: {

    },
    data() {
        return {
            gridsFlag: true, //网格码开启与关闭
            gridsInfo: '',
            gridsInfoShowFlag: false,
        }
    },
    async mounted() {
        this.$nextTick(() => {
            let toolBarDom = document.querySelector('.qysStyleRight');
            toolBarDom.style.right = (0 + 20) + 'px';
            if (this.$route.path === "/grids") {
                toolBarDom.style.right = '5px';
                toolBarDom.style.display = 'none';
            }
        });
    },
    methods: {
        async cesium_layer_change_mode(fn, modes) {
            await fn(modes.GLOBE)
            window.viewer.scene.fxaa = true;
            window.viewer.scene.postProcessStages.fxaa.enabled = true;
            this.removeJagged();
            // 航线相关entity集合
            airLineEntityColloction = new Cesium.CustomDataSource('airLineEntityColloctionSingleGrids');
            window.viewer.dataSources.add(airLineEntityColloction);
            // 初始化定位芜湖市！！！
            await window.viewer.camera.flyTo({
                // destination: Cesium.Cartesian3.fromDegrees(120.218241, 33.165767, 21812.321476599747),
                destination: Cesium.Cartesian3.fromDegrees(118.22073, 29.50576, 151812.321476599747),
                orientation: {
                    heading: 0.006918732929549698,
                    pitch: -0.6928665494487145,
                    roll: 7.638334409421077e-14
                },
                duration: 3,
                complete: () => {
                    // 初始化网格
                    myheight = 4000;
                    this.initGridsByOriginDataAndDistance(myheight, 3);
                    this.addAirLineGrid();
                    this.leftClickPop();
                    this.bindMapMove();
                }
            });
        },
        leftClickPop() {
            window.viewer.camera.moveEnd.addEventListener(() => {
                if (!this.gridsFlag) {
                    return;
                }
                this.gridsInfoShowFlag = false;
                let tileRender = window.viewer.scene.globe._surface._tilesToRender;
                let level = (tileRender && tileRender.length > 0) ? tileRender[0]._level : 9;
                console.log('当前地图等级：', level);
                if(level == currentLevel){
                    this.gridsInfoShowFlag = true;
                    return;
                }
                currentLevel = level;
                // if (currentLevel <= 7) {
                //     if (myheight != 4000) {
                //         myheight = 4000;
                //         this.gridsInfo = `${myheight}*${myheight}*${myheight}`;
                //         this.clearGridsAirLineDiv();
                //         this.initGridsByOriginDataAndDistance(4000, 6);
                //     }
                // }
                // else if (currentLevel == 8) {
                //     if (myheight != 3500) {
                //         myheight = 3500;
                //         this.gridsInfo = `${myheight}*${myheight}*${myheight}`;
                //         this.clearGridsAirLineDiv();
                //         this.initGridsByOriginDataAndDistance(3500, 6);
                //     }
                // }
                // else if (currentLevel == 9) {
                //     if (myheight != 3000) {
                //         myheight = 3000;
                //         this.gridsInfo = `${myheight}*${myheight}*${myheight}`;
                //         this.clearGridsAirLineDiv();
                //         this.initGridsByOriginDataAndDistance(3000, 6);
                //         this.addAirLineGrid();
                //     }
                // }
                // else if (currentLevel == 10) {
                //     if (myheight != 2000) {
                //         myheight = 2000;
                //         this.gridsInfo = `${myheight}*${myheight}*${myheight}`;
                //         this.clearGridsAirLineDiv();
                //         this.initGridsByOriginDataAndDistance(2000, 6);
                //         this.addAirLineGrid();
                //     }
                // }
                // else if (currentLevel == 11) {
                //     if (myheight != 1500) {
                //         myheight = 1500;
                //         this.gridsInfo = `${myheight}*${myheight}*${myheight}`;
                //         this.clearGridsAirLineDiv();
                //         this.initGridsByOriginDataAndDistance(1500, 6);
                //         this.addAirLineGrid();
                //     }
                // }
                // else if (currentLevel == 12) {
                //     if (myheight != 1000) {
                //         myheight = 1000;
                //         this.gridsInfo = `${myheight}*${myheight}*${myheight}`;
                //         this.clearGridsAirLineDiv();
                //         this.initGridsByOriginDataAndDistance(1000, 6);
                //         this.addAirLineGrid();
                //     }
                // }
                // else if (currentLevel == 13) {
                //     if (myheight != 800) {
                //         myheight = 800;
                //         this.gridsInfo = `${myheight}*${myheight}*${myheight}`;
                //         this.clearGridsAirLineDiv();
                //         this.initGridsByOriginDataAndDistance(800, 5);
                //         this.addAirLineGrid();
                //     }
                // }
                // else if (currentLevel == 14) {
                //     if (myheight != 600) {
                //         myheight = 600;
                //         this.gridsInfo = `${myheight}*${myheight}*${myheight}`;
                //         this.clearGridsAirLineDiv();
                //         this.initGridsByOriginDataAndDistance(600, 6);
                //         this.addAirLineGrid();
                //     }
                // }
                // else if (currentLevel == 15) {
                //     if (myheight != 500) {
                //         myheight = 500;
                //         this.gridsInfo = `${myheight}*${myheight}*${myheight}`;
                //         this.clearGridsAirLineDiv();
                //         this.initGridsByOriginDataAndDistance(500, 6);
                //         this.addAirLineGrid();
                //     }
                // }
                // else if (currentLevel == 16) {
                //     if (myheight != 450) {
                //         myheight = 450;
                //         this.gridsInfo = `${myheight}*${myheight}*${myheight}`;
                //         this.clearGridsAirLineDiv();
                //         this.initGridsByOriginDataAndDistance(450, 6);
                //         this.addAirLineGrid();
                //     }
                // }
                // else if (currentLevel >= 17) {
                //     if (myheight != 400) {
                //         myheight = 400;
                //         this.gridsInfo = `${myheight}*${myheight}*${myheight}`;
                //         this.clearGridsAirLineDiv();
                //         this.initGridsByOriginDataAndDistance(400, 6);
                //         this.addAirLineGrid();
                //     }
                // }
            });
        },
        // 随地图移动
        bindMapMove() {
            window.viewer.scene.preRender.addEventListener((e) => {
                try {
                    let a = window.viewer.dataSources._dataSources;
                    let b = null;
                    let d = null;
                    for (let i = 0; i < a.length; i++) {
                        if (a[i]._name == 'airLineEntityColloctionSingleGrids') {
                            d = a[i]._entityCollection._entities._array;
                        }
                    }
                    for (let m = 0; m < d.length; m++) {
                        if (d[m]._name && d[m]._name == 'airline-grid-single-grids') {
                            if (d[m]?.myDom) {
                                let dom = d[m].myDom;
                                let c = d[m]._position._value
                                let position = Cesium.SceneTransforms.wgs84ToWindowCoordinates(window.viewer.scene, c)
                                dom.style.left = (parseFloat(position.x) - 134) + 'px'
                                dom.style.top = (parseFloat(position.y) - 180) + 'px'
                            }
                        }
                    }
                } catch (e) {
                    console.log('bindMapMove...error', e);
                }
            });
        },
        /**@Description :  ******************************************* 贴地多边形
         **@Date: 2024-04-18 10:29:28
        */
        t1() {

            // 创建贴地面几何实例
            const instance = new Cesium.GeometryInstance({
                id: "PolygonGeometry",
                geometry: new Cesium.PolygonGeometry({
                    polygonHierarchy: new Cesium.PolygonHierarchy(
                        Cesium.Cartesian3.fromDegreesArray([
                            79.0, 32.0,
                            78.0, 31.0,
                            80.0, 30.0,
                            81.0, 28.0,
                            82.0, 23.0
                        ])
                    )
                }),
            });

            // 根据几何实例创建贴地面图元
            const primitive = new Cesium.GroundPrimitive({
                geometryInstances: instance,  //可以是实例数组
                appearance: new Cesium.MaterialAppearance({
                    material: Cesium.Material.fromType('Color', {
                        color: Cesium.Color.BLUE.withAlpha(0.3)
                    }),
                })
            });

            // 将图元添加到集合
            window.viewer.scene.primitives.add(primitive)
        },
        t2() {
            let instanceArray = [];
            let instanceArrayOutline = [];
            let slng = 79, slat = 30;
            for (let i = 0; i < 10; i++) {
                let elng = slng + 0.1 * i;
                let elngR = slng + 0.2 * (i + 1);
                let elat = slat;
                let elatT = slat;
                console.log('elng, elngR', elng, elngR);


                for (let j = 0; j < 10; j++) {
                    elat = slat + 0.1 * j;
                    elatT = slat + 0.2 * (j + 1);
                    let instance = new Cesium.GeometryInstance({
                        id: "PolygonGeometry",
                        geometry: new Cesium.PolygonGeometry({
                            polygonHierarchy: new Cesium.PolygonHierarchy(
                                Cesium.Cartesian3.fromDegreesArray([
                                    elng, elat,
                                    elng, elatT,
                                    elngR, elatT,
                                    elngR, elat,
                                    elng, elat,
                                ])
                            )
                        }),
                        attributes: {
                            color: new Cesium.ColorGeometryInstanceAttribute(0.0, 1.0, 1.0, 0.3)
                            // color: new Cesium.ColorGeometryInstanceAttribute(r, g, b, 0.3)
                        }
                    });


                    let polygonOutlineInstance = new Cesium.GeometryInstance({
                        geometry: new Cesium.GroundPolylineGeometry({
                            positions: Cesium.Cartesian3.fromDegreesArray([
                                elng, elat,
                                elng, elatT,
                                elngR, elatT,
                                elngR, elat,
                                elng, elat,
                            ]),
                            width: 1.0,
                        }),
                        attributes: {
                            color: new Cesium.ColorGeometryInstanceAttribute(1.0, 1.0, 1.0, 1.0)
                            // color: new Cesium.ColorGeometryInstanceAttribute(r, g, b, 0.9)
                        }
                    });
                    instanceArray.push(instance);
                    instanceArrayOutline.push(polygonOutlineInstance);
                }








            }
            window.viewer.scene.primitives.add(new Cesium.GroundPrimitive({
                geometryInstances: instanceArray
            }));
            window.viewer.scene.primitives.add(new Cesium.GroundPolylinePrimitive({
                geometryInstances: instanceArrayOutline,
                appearance: new Cesium.PolylineColorAppearance()
            }));
        },
        /**@Description :  ******************************************* 网格码入口
         **@Date: 2024-04-23 13:36:12
        */
        async initGridsByOriginDataAndDistance(distance, tier) {
            this.gridsInfoShowFlag = false;
            // 存储各个区域的正方形网格码数据
            let gridList = [];
            console.log('开始加载网格数据');
            let d1 = new Date();
            // 获取当前视图范围      
            let Rectangle = window.viewer.camera.computeViewRectangle();
            let viewerExtent = [
                Rectangle.west / Math.PI * 180,
                Rectangle.south / Math.PI * 180,
                Rectangle.east / Math.PI * 180,
                Rectangle.north / Math.PI * 180];
            // 当前视野面turf格式
            let viewerExtentPolyGon = turf.polygon([[
                [viewerExtent[0], viewerExtent[1]], // 注意：polygon首尾坐标要一致
                [viewerExtent[0], viewerExtent[3]],
                [viewerExtent[2], viewerExtent[3]],
                [viewerExtent[2], viewerExtent[1]],
                [viewerExtent[0], viewerExtent[1]]
            ]]);
            // 遍历原始网格数据，将当前视野内的网格码数据重新生成等边网格码
            for (let i = 0; i < currentGridsOriginData.length; i++) {
                let maxLon = _.maxBy(currentGridsOriginData[i].data, 'r').r;
                let minLon = _.minBy(currentGridsOriginData[i].data, 'l').l;
                let maxLat = _.maxBy(currentGridsOriginData[i].data, 't').t;
                let minLat = _.minBy(currentGridsOriginData[i].data, 'b').b;
                let originGridBounds = { maxLon, minLon, maxLat, minLat };
                //当前网格码面
                let maxGridPolygon = turf.polygon([[[minLon, minLat], [minLon, maxLat], [maxLon, maxLat], [maxLon, minLat], [minLon, minLat]]]);
                //判断是否有交集
                let intersection = turf.intersect(viewerExtentPolyGon, maxGridPolygon);
                if (intersection) {
                    //将相交的原始网格码数据，重新生成等边网格码
                    let newGrids = this.reGenerateGridsByDistance(currentGridsOriginData[i].data, distance, originGridBounds);
                    let temp = {};
                    temp.id = currentGridsOriginData[i].id;
                    temp.data = newGrids.data;
                    temp.bounds = newGrids.bounds;
                    gridList.push(temp);
                }
                // console.log(`第 ${i + 1} 个网格码生成完成，id为${currentGridsOriginData[i].id}`);
            }
            let d2 = new Date();
            console.log('生成网格数据耗时: ', d2.getTime() - d1.getTime(), '毫秒');
            // 根据重新生成的网格码数据，绘制网格
            console.log('开始绘制网格码到地图上');
            this.addThreeDimensionalGridFun(gridList, distance, tier);
            console.log('gridList', gridList);
            
            let d3 = new Date();
            console.log('绘制网格码结束，耗时: ', d3.getTime() - d2.getTime());
            setTimeout(() => {
                this.gridsInfoShowFlag = true;
                this.gridsInfo = `${myheight}*${myheight}*${myheight}`;
            }, 1000);
        },
        /**@Description :  ******************************************* 
         **@Date: 2024-04-19 10:03:05
         **@param {Array} oriGrids 原始网格码数据
         **@param {Number} distance 网格间距
         **@param {Number} originGridBounds 网格最大最小范围的经度纬  maxLon, minLon, maxLat, minLat
        */
        reGenerateGridsByDistance(oriGrids, distance, originGridBounds) {
            // 获取原始网格边界 getOriGridsBounds
            let oriGridsRealBounds = this.getOriGridsBounds(oriGrids);
            let oriGridsRealBoundsPolygon = turf.polygon([oriGridsRealBounds]);
            let res = cesiumSpaceAPI.generateSquareGrids(distance, originGridBounds, oriGridsRealBoundsPolygon);
            return res;
        },
        getOriGridsBounds(grids) {
            let lngMaxItem = _.maxBy(grids, 'r');
            let lngMinItem = _.minBy(grids, 'l');
            let latMaxItem = _.maxBy(grids, 't');
            let latMinItem = _.minBy(grids, 'b');
            let ycList = [];
            let zcList = [];
            let ubList = [];
            let dbList = [];
            let ycObjList = [];
            let zcObjList = [];
            let ubObjList = [];
            let dbObjList = [];
            for (let i = 0; i < grids.length; i++) {
                let pp1 = [grids[i].l, grids[i].b];
                let pp2 = [grids[i].l, grids[i].t];
                let pp3 = [grids[i].r, grids[i].t];
                let pp4 = [grids[i].r, grids[i].b];
                let fp = [pp1, pp2, pp3, pp4];
                for (let ii = 0; ii < 4; ii++) {
                    let clng = fp[ii][0];
                    let clat = fp[ii][1];
                    //右侧线
                    if (clng == lngMaxItem.r) {
                        ycList.push([clng, clat]);
                        ycObjList.push({ lng: clng, lat: clat });
                    }
                    //左侧线
                    if (clng == lngMinItem.l) {
                        zcList.push([clng, clat]);
                        zcObjList.push({ lng: clng, lat: clat });
                    }
                    //顶上线
                    if (clat == latMaxItem.t) {
                        ubList.push([clng, clat]);
                        ubObjList.push({ lng: clng, lat: clat });
                    }
                    //底下线
                    if (clat == latMinItem.b) {
                        dbList.push([clng, clat]);
                        dbObjList.push({ lng: clng, lat: clat });
                    }
                }
            }
            let a = _.orderBy(ycObjList, 'lat', 'asc');
            let b = _.orderBy(ubObjList, 'lng', 'desc');
            let c = _.orderBy(zcObjList, 'lat', 'desc');
            let d = _.orderBy(dbObjList, 'lng', 'asc');
            ycList = [];
            zcList = [];
            ubList = [];
            dbList = [];
            _(a).forEach((item) => {
                ycList.push([item.lng, item.lat]);
            });
            _(b).forEach((item) => {
                ubList.push([item.lng, item.lat]);
            });
            _(c).forEach((item) => {
                zcList.push([item.lng, item.lat]);
            });
            _(d).forEach((item) => {
                dbList.push([item.lng, item.lat]);
            });
            let aP = [...ycList, ...ubList, ...zcList, ...dbList];
            let line = turf.lineString(aP);
            let res = turf.cleanCoords(line).geometry.coordinates;
            res.push(res[0]);
            return res;
        },
        /**@Description :  ******************************************* 绘制网格码入口
         **@Date: 2024-04-22 13:36:03
         **@param {Array} gridList 重新生成的正方形网格码数据
         **@param {Number} distance 网格间距，拉伸高度
         **@param {Number} tier 网格拉伸几层
        */
        addThreeDimensionalGridFun(gridList, distance, tier) {
            let defaultGridColor = 'rgba(0, 255, 194, 0.3)';//默认网格码颜色
            let gridOutLineColor = 'rgba(255, 255, 255, 0.5)';//网格码边框颜色
            this.addThreeDimensionalGrid(gridList, distance, tier, defaultGridColor, gridOutLineColor);
        },
        /**@Description :  ******************************************* 绘制贴地网格码
         **@Date: 2024-04-22 13:38:01
        */
        addThreeDimensionalGrid(gridList, distance, tier, defaultGridColor, gridOutLineColor) {
            let threeDimensionalGridprimitives = null;
            threeDimensionalGridprimitivesList = [];
            // 遍历每一个区域网格数据
            for (let i = 0; i < gridList.length; i++) {
                // 为每一个区域创建一个PrimitiveCollection集合，存储拉伸的网格和边框
                threeDimensionalGridprimitives = new Cesium.PrimitiveCollection();
                // 单一一个区域网格数据
                let grids = gridList[i];
                let id = grids.id;
                let polygonOutlineInstanceArray = [];
                let polygonInstanceArray = [];
                // 对每一个网格进行处理，绘制贴地网格和边框
                for (let j = 0; j < grids.data.length; j++) {
                    let item = grids.data[j];
                    // 计算每个网格面
                    let polygonPos = [item.r, item.b, 0, item.r, item.t, 0, item.l, item.t, 0, item.l, item.b, 0];
                    // 添加网格的索引编号
                    let midC3 = new Cesium.Cartesian3.fromDegrees((item.r + item.l) / 2, (item.b + item.t) / 2, 0);
                    let labelEn = new Cesium.Entity({
                        position: midC3,
                        label: {
                            text: `zhz-${j + 1}`,
                            // show: true,
                            show: new Cesium.ConstantProperty(true),
                            showBackground: false,
                            font: '12px monospace normal',
                            horizontalOrigin: Cesium.HorizontalOrigin.CENTER,
                            // verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
                            verticalOrigin: new Cesium.ConstantProperty(Cesium.VerticalOrigin.TOP),
                            pixelOffset: new Cesium.Cartesian2(0, 0),
                            disableDepthTestDistance: Number.POSITIVE_INFINITY,
                            // heightReference: Cesium.HeightReference.CLAMP_TO_GROUND,
                            heightReference: new Cesium.ConstantProperty(Cesium.HeightReference.CLAMP_TO_GROUND),
                            disableDepthTestDistance: 99000000,
                            // fillColor: Cesium.Color.fromCssColorString('rgba(106, 245, 235, 1)'), // 字体颜色
                            fillColor: Cesium.Color.fromCssColorString('rgba(255, 255, 255, 1)'), // 字体颜色
                            translucencyByDistance: new Cesium.NearFarScalar(200, 1, 2500000, 0),
                            scaleByDistance: new Cesium.NearFarScalar(200, 1, 2500000, 0)
                        },
                    });
                    airLineEntityColloction.entities.add(labelEn);
                    // 网格的primitive id
                    let insId = id + '_' + j + '_' + 'grid_box';
                    // 创建网格和边框的instance，并保存到数组中统一添加到PrimitiveCollection中
                    let polygonInstance = cesiumSpaceAPI.generatePolygonInstance(polygonPos, defaultGridColor, insId);
                    let polygonOutlineInstance = cesiumSpaceAPI.generatePolygonOutlineInstance(polygonPos, gridOutLineColor);
                    polygonInstanceArray.push(polygonInstance);
                    polygonOutlineInstanceArray.push(polygonOutlineInstance);
                }
                // 创建一个区域的primitive，包括网格和边框，并添加到primitive集合中
                let polygonPrimitive = new Cesium.GroundPrimitive({
                    geometryInstances: polygonInstanceArray,
                    releaseGeometryInstances: false,
                    asynchronous: false,
                });
                let polygonOutlinePrimitive = new Cesium.GroundPolylinePrimitive({
                    geometryInstances: polygonOutlineInstanceArray,
                    appearance: new Cesium.PolylineColorAppearance(),
                    asynchronous: false,
                });
                threeDimensionalGridprimitives.add(polygonPrimitive);
                threeDimensionalGridprimitives.add(polygonOutlinePrimitive);
                window.viewer.scene.primitives.add(threeDimensionalGridprimitives);
                // 将单个区域网格信息添加到全局变量中
                threeDimensionalGridprimitivesList.push({
                    'id': id,
                    'primitiveCollection': threeDimensionalGridprimitives,
                    'polygonPrimitive': polygonPrimitive,
                    'bounds': grids.bounds,
                });
            }
        },
        // 消除锯齿
        removeJagged() {
            window.viewer.scene.postProcessStages.fxaa.enabled = true
            window.viewer.scene.fxaa = true
            const supportsImageRenderingPixelated = window.viewer.cesiumWidget._supportsImageRenderingPixelated
            if (supportsImageRenderingPixelated) {
                let vtxf_dpr = window.devicePixelRatio
                while (vtxf_dpr >= 2.0) {
                    vtxf_dpr /= 2.0
                }
                window.viewer.resolutionScale = vtxf_dpr
            }
        },
        /**@Description :  ******************************************* 添加航线和航线经过的网格
         **@Date: 2024-04-19 13:38:26
        */
        addAirLineGrid() {
            // mainColor = 'rgba(255, 0, 0, 0.2)';
            // bufferColor = 'rgba(255,170,37, 0.1)';
            airlineGridsPrimitiveList = [];
            airlineGridsPrimitiveOutLintList = [];
            let lineDatasWUHU = [
                {
                    index: 0,
                    flightName: '高圆村-合肥工业大学宣城小区',
                    deviceName: '联飞芜湖Q20#001',
                    startTime: '2024.03.24 12:00:00',
                    endTime: '2024.03.24 12:30:00',
                    crossGridColor: 'rgba(223, 70, 66, 0.5)',
                    bufferColor : 'rgba(255,170,37, 0.1)',
                    data: [
                        { "lon": 118.15341002, "lat": 30.54857663, "alt": 0 },
                        { "lon": 118.19204213, "lat": 30.52315569, "alt": 1200 },
                        { "lon": 118.25204213, "lat": 30.56315569, "alt": 2500 },
                        { "lon": 118.28204213, "lat": 30.62315569, "alt": 3500 },
                        { "lon": 118.38204213, "lat": 30.68315569, "alt": 3500 },
                        { "lon": 118.46204213, "lat": 30.75315569, "alt": 3500 },
                        { "lon": 118.59204213, "lat": 30.83315569, "alt": 5000 },
                        { "lon": 118.70784712, "lat": 30.90779506, "alt": 200 },
                    ]
                },
                {
                    index: 1,
                    flightName: '红旗村-溪口镇',
                    deviceName: '联飞芜湖Q20#001',
                    startTime: '2024.03.24 12:00:00',
                    endTime: '2024.03.24 12:30:00',
                    crossGridColor: 'rgba(223, 70, 66, 0.5)',
                    bufferColor : 'rgba(255,170,37, 0.1)',
                    data: [
                        { "lon": 118.12017506, "lat": 30.73133667, "alt": 10 },
                        { "lon": 118.19204213, "lat": 30.72315569, "alt": 1200 },
                        { "lon": 118.25204213, "lat": 30.75315569, "alt": 2500 },
                        { "lon": 118.28204213, "lat": 30.70315569, "alt": 2800 },
                        { "lon": 118.38204213, "lat": 30.68315569, "alt": 3000 },
                        { "lon": 118.46204213, "lat": 30.75315569, "alt": 3500 },
                        { "lon": 118.59204213, "lat": 30.69315569, "alt": 3000 },
                        { "lon": 118.7142125, "lat": 30.67952039, "alt": 10 },
                    ]
                },
                {
                    index: 2,
                    flightName: '凤凰山村-芦乔村',
                    deviceName: '联飞芜湖Q20#001',
                    startTime: '2024.03.24 12:00:00',
                    endTime: '2024.03.24 12:30:00',
                    // crossGridColor:'rgba(252, 192, 17, 0.7)',
                    // crossGridColor:'rgba(48, 105, 240, 0.5)',
                    crossGridColor:'rgba(19, 134, 226, 0.5)',
                    bufferColor : 'rgba(255,170,37, 0.1)',
                    data: [
                        { "lon": 118.01997669, "lat": 30.8783321, "alt": 0 },
                        { "lon": 118.19204213, "lat": 30.92315569, "alt": 1200 },
                        { "lon": 118.25204213, "lat": 31.10315569, "alt": 2500 },
                        { "lon": 118.28204213, "lat": 31.12315569, "alt": 2000 },
                        { "lon": 118.37809546, "lat": 31.14245536, "alt": 200 },
                    ]
                }
            ];
            for (let ii = 0; ii < lineDatasWUHU.length; ii++) {
                // 添加航线
                let lineSegment = [];
                lineSegment = this.addAirLineEntity(lineDatasWUHU[ii], 'rgba(255, 255, 255, 0.5)', 2);
                // 计算航线网格
                let intersectGridsAll = [];
                // 遍历每个区域的网格
                for (let i = 0; i < threeDimensionalGridprimitivesList.length; i++) {
                    let gridsIntersect = [];
                    // 该区域的网格primitive
                    let gridsPrimitives = threeDimensionalGridprimitivesList[i].polygonPrimitive;
                    let id = threeDimensionalGridprimitivesList[i].id;
                    let instanceArray = gridsPrimitives.geometryInstances;
                    // 对primitive进行遍历
                    for (let j = 0; j < instanceArray.length; j++) {
                        let instance = instanceArray[j];
                        let array = instance.geometry._polygonHierarchy.positions;
                        // 每一个网格的四个角点
                        let gridsPointList = [];
                        array.forEach((item, index) => {
                            let p = cesiumSpaceAPI.toDegrees(array[index]);
                            gridsPointList.push(p);
                        });
                        let tempHight = myheight;
                        // 设置拉伸6层
                        for (let m = 0; m < 6; m++) {
                            let height = tempHight * m;
                            // 生成六个立方体，判断每一个立方体跟航线是否相交，如果相交，则巴这个立方体收集起来
                            //底
                            let face11 = [{ lon: gridsPointList[0].lng, lat: gridsPointList[0].lat, height: height },
                            { lon: gridsPointList[1].lng, lat: gridsPointList[1].lat, height: height },
                            { lon: gridsPointList[2].lng, lat: gridsPointList[2].lat, height: height },
                            { lon: gridsPointList[3].lng, lat: gridsPointList[3].lat, height: height },
                            ];
                            //顶
                            let face12 = [{ lon: gridsPointList[0].lng, lat: gridsPointList[0].lat, height: height + tempHight },
                            { lon: gridsPointList[1].lng, lat: gridsPointList[1].lat, height: height + tempHight },
                            { lon: gridsPointList[2].lng, lat: gridsPointList[2].lat, height: height + tempHight },
                            { lon: gridsPointList[3].lng, lat: gridsPointList[3].lat, height: height + tempHight },
                            ];
                            //前
                            let face13 = [{ lon: gridsPointList[0].lng, lat: gridsPointList[0].lat, height: height },
                            { lon: gridsPointList[0].lng, lat: gridsPointList[0].lat, height: height + tempHight },
                            { lon: gridsPointList[3].lng, lat: gridsPointList[3].lat, height: height + tempHight },
                            { lon: gridsPointList[3].lng, lat: gridsPointList[3].lat, height: height },
                            ];
                            //后
                            let face14 = [{ lon: gridsPointList[1].lng, lat: gridsPointList[1].lat, height: height },
                            { lon: gridsPointList[1].lng, lat: gridsPointList[1].lat, height: height + tempHight },
                            { lon: gridsPointList[2].lng, lat: gridsPointList[2].lat, height: height + tempHight },
                            { lon: gridsPointList[2].lng, lat: gridsPointList[2].lat, height: height },
                            ];
                            //左
                            let face15 = [{ lon: gridsPointList[2].lng, lat: gridsPointList[2].lat, height: height },
                              { lon: gridsPointList[2].lng, lat: gridsPointList[2].lat, height: height + tempHight },
                              { lon: gridsPointList[3].lng, lat: gridsPointList[3].lat, height: height + tempHight },
                              { lon: gridsPointList[3].lng, lat: gridsPointList[3].lat, height: height },
                              ];
                              //右
                              let face16 = [{ lon: gridsPointList[1].lng, lat: gridsPointList[1].lat, height: height },
                              { lon: gridsPointList[1].lng, lat: gridsPointList[1].lat, height: height + tempHight },
                              { lon: gridsPointList[0].lng, lat: gridsPointList[0].lat, height: height + tempHight },
                              { lon: gridsPointList[0].lng, lat: gridsPointList[0].lat, height: height },
                            ];
                            // 判断每一个立方体跟航线是否相交
                            let isIns = cesiumSpaceAPI.judgeSegmentCubeIntersect([face11, face12, face13, face14, face15, face16], lineSegment);
                            if (isIns) {
                                //把当前这个立方体存起来
                                let temp = {};
                                temp.id = id;
                                temp.instance = instance;
                                temp.gridsPointList = gridsPointList;
                                temp.m = m;
                                temp.height = height;
                                gridsIntersect.push(temp);
                            }
                        }
                    }
                    intersectGridsAll.push(gridsIntersect);
                }
                // 开始绘制网格
                for (let k = 0; k < intersectGridsAll.length; k++) {
                    let mainColor = lineDatasWUHU[ii].crossGridColor;
                    let bufferColor = lineDatasWUHU[ii].bufferColor;
                    let intersectGrid = intersectGridsAll[k];
                    let instanceArr = []; //航线经过的立方体网格
                    let polygonOutlineInstanceArray = [];
                    let primitiveCollection = new Cesium.PrimitiveCollection();
                    intersectGrid.forEach((item, index) => {
                        let gridsPointList = item.gridsPointList;
                        let p1 = new Cesium.Cartesian3.fromDegrees(gridsPointList[0].lng, gridsPointList[0].lat, gridsPointList[0].alt + item.m * 0);
                        let p2 = new Cesium.Cartesian3.fromDegrees(gridsPointList[1].lng, gridsPointList[1].lat, gridsPointList[1].alt + item.m * 0);
                        let p3 = new Cesium.Cartesian3.fromDegrees(gridsPointList[2].lng, gridsPointList[2].lat, gridsPointList[2].alt + item.m * 0);
                        let p4 = new Cesium.Cartesian3.fromDegrees(gridsPointList[3].lng, gridsPointList[3].lat, gridsPointList[3].alt + item.m * 0);
                        let polygonPos = [p1, p2, p3, p4];
                        let cesiumColor = Cesium.Color.fromCssColorString(mainColor);
                        let cesiumBufferColor = Cesium.Color.fromCssColorString(bufferColor);
                        // 立方体网格
                        let polygon = new Cesium.PolygonGeometry({
                            polygonHierarchy: new Cesium.PolygonHierarchy(polygonPos),
                            vertexFormat: Cesium.PerInstanceColorAppearance.VERTEX_FORMAT,
                            extrudedHeight: (item.m + 1) * myheight, //拉伸
                            height: (item.m) * myheight //高度，必须设置，要不要然会从贴地拉伸
                        });
                        let polygonGeometry = Cesium.PolygonGeometry.createGeometry(polygon);
                        let polygonInstance = new Cesium.GeometryInstance({
                            geometry: polygonGeometry,
                            attributes: {
                                color: Cesium.ColorGeometryInstanceAttribute.fromColor(cesiumColor)
                            }
                        });
                        instanceArr.push(polygonInstance);
                        // 上下左右前后缓冲网格
                        let polygonDown = null;
                        let polygonUp = cesiumSpaceAPI.generatePolygonInstanceEx(polygonPos, (item.m + 2) * myheight, (item.m + 1) * myheight, bufferColor);
                        if (item.m >= 1) {
                            polygonDown = cesiumSpaceAPI.generatePolygonInstanceEx(polygonPos, (item.m) * myheight, (item.m - 1) * myheight, bufferColor);
                        }
                        let ePointRight = turf.point([gridsPointList[0].lng, gridsPointList[0].lat]);
                        let p11 = turf.destination(ePointRight, myheight, 90, { units: 'meters' });
                        let c3P11 = new Cesium.Cartesian3.fromDegrees(p11.geometry.coordinates[0], p11.geometry.coordinates[1], 0);
                        let c3P12 = new Cesium.Cartesian3.fromDegrees(p11.geometry.coordinates[0], gridsPointList[1].lat, 0);
                        let polygonPosRight = [c3P11, c3P12, p2, p1];
                        let polygonRight = cesiumSpaceAPI.generatePolygonInstanceEx(polygonPosRight, (item.m + 1) * myheight, (item.m) * myheight,
                            bufferColor);
                        let ePointLeft = turf.point([gridsPointList[3].lng, gridsPointList[3].lat]);
                        let p11Left = turf.destination(ePointLeft, myheight, -90, { units: 'meters' });
                        let c3P41 = new Cesium.Cartesian3.fromDegrees(p11Left.geometry.coordinates[0], p11Left.geometry.coordinates[1], 0);
                        let c3P31 = new Cesium.Cartesian3.fromDegrees(p11Left.geometry.coordinates[0], gridsPointList[2].lat, 0);
                        let polygonPosLeft = [p4, p3, c3P31, c3P41];
                        let polygonLeft = cesiumSpaceAPI.generatePolygonInstanceEx(polygonPosLeft, (item.m + 1) * myheight, (item.m) * myheight,
                            bufferColor);
                        let ePointFront = turf.point([gridsPointList[1].lng, gridsPointList[1].lat]);
                        let p11Front = turf.destination(ePointFront, myheight, 0, { units: 'meters' });
                        let c3P111 = new Cesium.Cartesian3.fromDegrees(p11Front.geometry.coordinates[0], p11Front.geometry.coordinates[1], 0);
                        let c3P121 = new Cesium.Cartesian3.fromDegrees(gridsPointList[2].lng, p11Front.geometry.coordinates[1], 0);
                        let polygonPosFront = [p2, c3P111, c3P121, p3];
                        let polygonFront = cesiumSpaceAPI.generatePolygonInstanceEx(polygonPosFront, (item.m + 1) * myheight, (item.m) * myheight,
                            bufferColor);
                        let ePointBack = turf.point([gridsPointList[0].lng, gridsPointList[0].lat]);
                        let p11Back = turf.destination(ePointBack, myheight, 180, { units: 'meters' });
                        let c3Pb111 = new Cesium.Cartesian3.fromDegrees(p11Back.geometry.coordinates[0], p11Back.geometry.coordinates[1], 0);
                        let c3Pb121 = new Cesium.Cartesian3.fromDegrees(gridsPointList[3].lng, p11Back.geometry.coordinates[1], 0);
                        let polygonPosBack = [c3Pb111, p1, p4, c3Pb121];
                        let polygonBack = cesiumSpaceAPI.generatePolygonInstanceEx(polygonPosBack, (item.m + 1) * myheight, (item.m) * myheight,
                            bufferColor);
                        instanceArr.push(polygonUp);
                        polygonDown && instanceArr.push(polygonDown);
                        instanceArr.push(polygonRight);
                        instanceArr.push(polygonLeft);
                        instanceArr.push(polygonFront);
                        instanceArr.push(polygonBack);
                        let h = (item.m) * myheight;
                        let eh = (item.m + 1) * myheight;
                        let itemm = { r: gridsPointList[0].lng, b: gridsPointList[2].lng, b: gridsPointList[0].lat, t: gridsPointList[1].lat };
                        let polygonOutlinePos = [
                            itemm.r, itemm.b, eh,
                            itemm.r, itemm.t, eh,
                            itemm.l, itemm.t, eh,
                            itemm.l, itemm.b, eh,
                            itemm.r, itemm.b, eh,
                            itemm.r, itemm.b, h,
                            itemm.r, itemm.t, h,
                            itemm.l, itemm.t, h,
                            itemm.l, itemm.b, h,
                            itemm.r, itemm.b, h,
                            itemm.l, itemm.b, h,
                            itemm.l, itemm.b, eh,
                            itemm.l, itemm.t, eh,
                            itemm.l, itemm.t, h,
                            itemm.r, itemm.t, h,
                            itemm.r, itemm.t, eh,
                        ];
                        let polygonOutlineInstance = new Cesium.GeometryInstance({
                            geometry: Cesium.CoplanarPolygonOutlineGeometry.fromPositions({
                                positions: Cesium.Cartesian3.fromDegreesArrayHeights(polygonOutlinePos),
                            }),
                            attributes: {
                                color: Cesium.ColorGeometryInstanceAttribute.fromColor(Cesium.Color.fromCssColorString('rgba(255, 255, 255, 0.5)')),
                            },
                        });
                        polygonOutlineInstanceArray.push(polygonOutlineInstance);
                    });
                    let primitivePolygon = new Cesium.Primitive({
                        geometryInstances: instanceArr,
                        appearance: new Cesium.PerInstanceColorAppearance({
                            closed: true,
                        })
                    });
                    let primitivePolygonOutLine = new Cesium.Primitive({
                        geometryInstances: polygonOutlineInstanceArray,
                        appearance: new Cesium.PerInstanceColorAppearance({
                            flat: true,
                            renderState: {
                                lineWidth: Math.min(1.0, window.viewer.scene.maximumAliasedLineWidth),
                            },
                        }),
                    });
                    primitiveCollection.add(primitivePolygon);
                    primitiveCollection.add(primitivePolygonOutLine);
                    window.viewer.scene.primitives.add(primitiveCollection);
                    let temp = {
                        polygon: primitivePolygon,
                        outline: primitivePolygonOutLine,
                        collection: primitiveCollection,
                    };
                    airlineGridsPrimitiveList.push(temp);
                }
            }
        },
        /**@Description :  ******************************************* 添加航线entity
         **@Date: 2024-04-23 16:04:58
        */
        addAirLineEntity(lineDataAll, airLineColor, airLineWidth) {
            let lineData = lineDataAll.data;
            let lineSegment = [];
            let endBillBoardEntity = null;
            for (let i = 0; i < lineData.length - 1; i++) {
                let p1 = lineData[i];
                let p2 = lineData[i + 1];
                p1.height = p1.alt;
                p2.height = p2.alt;
                lineSegment.push([p1, p2]);
                let pos1 = new Cesium.Cartesian3.fromDegrees(p1.lon, p1.lat, p1.alt);
                let pos2 = new Cesium.Cartesian3.fromDegrees(p2.lon, p2.lat, p2.alt);
                let midPos = new Cesium.Cartesian3(0, 0, 0);
                Cesium.Cartesian3.midpoint(pos1, pos2, midPos);
                let azimuth = cesiumSpaceAPI.caculateAzimuthAngleByLonLat(p1, p2);
                if (i == 0) {
                    let en = new Cesium.Entity({
                        position: pos1,
                        billboard: {
                            image: 'image/s.png',
                            show: true,
                            scale: 0.8,
                            scaleByDistance: new Cesium.NearFarScalar(0.5e2, 1.0, 1.5e6, 0),
                            disableDepthTestDistance: Number.POSITIVE_INFINITY,
                        },
                    });
                    airLineEntityColloction.entities.add(en);
                }
                if (i == lineData.length - 2) {
                    endBillBoardEntity = new Cesium.Entity({
                        name: 'airline-grid-single-grids',
                        position: pos2,
                        billboard: {
                            image: 'image/e.png',
                            show: true,
                            scale: 0.8,
                            rotation: azimuth,
                            scaleByDistance: new Cesium.NearFarScalar(0.5e2, 1.0, 1.5e6, 0),
                            disableDepthTestDistance: Number.POSITIVE_INFINITY,
                        },
                    });
                    airLineEntityColloction.entities.add(endBillBoardEntity);
                }
                let midEn = new Cesium.Entity({
                    position: midPos,
                    billboard: {
                        image: 'image/l.png',
                        show: true,
                        scale: 0.8,
                        rotation: azimuth,
                        scaleByDistance: new Cesium.NearFarScalar(0.5e2, 1.0, 1.5e6, 0),
                        disableDepthTestDistance: Number.POSITIVE_INFINITY,
                    },
                });
                airLineEntityColloction.entities.add(midEn);
                let polylineEn = new Cesium.Entity({
                    polyline: {
                        positions: [pos1, pos2],
                        width: airLineWidth,
                        material: Cesium.Color.fromCssColorString(airLineColor),
                    },
                });
                airLineEntityColloction.entities.add(polylineEn);
            }
            //创建航线html标牌
            let last = lineData[lineData.length - 1];
            let cartesian3 = new Cesium.Cartesian3.fromDegrees(last.lon, last.lat, last.alt);
            // // 屏幕坐标
            let c2 = Cesium.SceneTransforms.wgs84ToWindowCoordinates(window.viewer.scene, cartesian3);
            console.log('c2', c2);
            let labelDiv = cesiumSpaceAPI.createAirLineLabel(c2, lineDataAll);
            labelDivList.push(labelDiv);
            endBillBoardEntity.myDom = labelDiv;
            return lineSegment;
        },

        clearGridsAirLineDiv() {
            for (let t = 0; t < threeDimensionalGridprimitivesList.length; t++) {
                threeDimensionalGridprimitivesList[t].primitiveCollection.removeAll();
            }
            for (let t = 0; t < airlineGridsPrimitiveList.length; t++) {
                airlineGridsPrimitiveList[t].collection.removeAll();
            }
            if (airLineEntityColloction) {
                airLineEntityColloction.entities.removeAll();
            }
            //销毁div
            for (let i = 0; i < labelDivList.length; i++) {
                labelDivList[i].remove();
            }
            threeDimensionalGridprimitivesList = [];
            airlineGridsPrimitiveList = [];
            labelDivList = [];
        },
    },
    computed: {
        ...mapGetters(['user_info']),
        home_popup() {
            return this.$refs.popup
        }
    },
    provide() {
        return {
            home_popup: () => this.home_popup,
            handle_check_popup_show: this.handle_check_popup_show
        }
    },
    beforeDestroy() {
        this.$store.commit('device/TOOLBAR_IS_SHOW', true)
    },
    inject: ['g_set_title']
}
</script>

<style lang="scss" scoped>
.page-grids {
    width: 100vw;
    height: 100%;
    background-color: #0c0e13;
}

.legend {
    z-index: 1000;
    position: fixed;
    right: 22.8vw;
    bottom: 2.8vh;
    // width: 100px;
    width: auto;
    height: 130px;
    background-color: #00f5ff;
    // background: linear-gradient( 1, rgba(24,95,146,0.71) 38%, rgba(0,59,83,0.78) 100%);
    // border-radius: 0px 0px 0px 0px;
    // border: 0 solid;
    // border-image: linear-gradient(180deg, rgba(71, 160, 255, 0.06), rgba(123, 187, 255, 1), rgba(71, 167, 255, 0.2)) 1 1;
    // background-image: linear-gradient(179deg,#185F92 1%,#003B53 100%);
    background: rgba(22, 39, 66, 0.56);
    border-radius: 0px 0px 0px 0px;
    border: 1px solid rgba(0, 245, 255, 0.12);

    .header {
        width: 100%;
        height: 20%;
        // background-color: #1e82f8;
        box-sizing: border-box;
        padding: 0 10px;
        // width: 56px;
        // height: 19px;
        font-family: Alibaba PuHuiTi, Alibaba PuHuiTi;
        font-weight: bold;
        font-size: 14px;
        line-height: 16px;
        text-align: left;
        font-style: normal;
        text-transform: none;
        background: linear-gradient(90deg, #FFFFFF 0%, #FFFFFF 35%, #56CCFF 96%);
        // color: linear-gradient(to right, red, yellow) !important;
        // background: linear-gradient(to right, #ff0000, #ffff00); /*设置渐变的方向从左到右 颜色从ff0000到ffff00*/
        -webkit-background-clip: text;
        /*将设置的背景颜色限制在文字中*/
        -webkit-text-fill-color: transparent;
        /*给文字设置成透明*/
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
    }

    .body {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        padding: 10px 0;

        .legend-item {
            width: 100%;
            height: 25%;
            // background-color: #00f5ff;
            box-sizing: border-box;
            padding: 10px 14px;
            display: flex;
            align-items: center;
            justify-content: flex-start;

            span {
                // width: 52px;
                // background-color: #00f5ff;
                height: 17px;
                font-family: PingFang SC, PingFang SC;
                font-weight: 500;
                font-size: 12px;
                color: #C4EAFF;
                line-height: 14px;
                text-align: left;
                font-style: normal;
                text-transform: none;
                letter-spacing: 1.0px;
                margin-left: 8px;
            }
        }
    }
}

.grids-tips {
    position: fixed;
    right: 2.8vw;
    bottom: .1vh;
    width: auto;
    height: 20px;
    background-color: rgba(20, 33, 63, 0.6);
    color: #FFFFFF;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 12px 10px;
    border-radius: 4px;
}

.grids-custom-loader {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    border: 3px solid;
    border-color: #E4E4ED;
    border-right-color: #1F516C;
    animation: s2 1s infinite linear;
}

@keyframes s2 {
    to {
        transform: rotate(1turn)
    }
}
</style>
<style lang="scss">
.airline-label {
    height: 155px !important;
    width: 268px !important;
    background-image: url('~@/assets/images/b.png') !important;
    background-repeat: no-repeat !important;
    background-size: 100% 100% !important;

    .header {
        height: 32px !important;
        width: 268px !important;
        background-image: url('~@/assets/images/h.png') !important;
        background-repeat: no-repeat !important;
        background-size: 100% 100% !important;
        display: flex;
        align-items: center;
        justify-content: space-between;

        span {
            color: #ffffff !important;
            font-size: 18px !important;
            font-weight: bold;
            margin-left: 20px;
            letter-spacing: 2px;
            font-style: italic;
        }

        .close {
            cursor: pointer;
            font-weight: normal;
            margin-right: 10px;
            font-style: normal;
        }
    }

    .body {
        height: 123px !important;
        width: 268px !important;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        div {
            width: 100%;
            height: 20%;
            box-sizing: border-box;
            padding: 0 20px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
        }

        .label {
            font-family: PingFang SC, PingFang SC;
            font-weight: 400;
            font-size: 14px;
            line-height: 14px;
            text-shadow: 0px 4px 6px rgba(24, 255, 251, 0.32), inset 0px 1px 4px rgba(255, 255, 255, 0.5);
            text-align: left;
            font-style: normal;
            text-transform: none;
            color: #73CFD4;
            width: 30%;
            display: flex;
            align-items: center;
            justify-content: flex-start;
        }

        .v {
            font-family: PingFang SC, PingFang SC;
            font-weight: 500;
            font-size: 13px;
            // line-height: 14px;
            // text-align: center;
            font-style: normal;
            text-transform: none;
            color: #ffffff;
            margin-left: 10px;
            width: 70%;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        .vt {
            font-size: 12px;
            align-items: left;
        }
    }
}
</style>